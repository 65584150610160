import {
  JoinableWorkspace,
  WorkspaceOpeningApplicationFormBusinessTypeEnum
} from '@/api/openapi';

export type SelectableWorkspace = JoinableWorkspace & {
  isChecked: boolean;
  imageUrl: string;
};

export type SearchResultItem = {
  id: number;
  label: string;
};

export type SelectOption<T> = { id: T; label: string };

export const businessTypeOptions: Array<SelectOption<
  WorkspaceOpeningApplicationFormBusinessTypeEnum
>> = [
  {
    id: WorkspaceOpeningApplicationFormBusinessTypeEnum.Advertiser,
    label: '広告主'
  },
  {
    id: WorkspaceOpeningApplicationFormBusinessTypeEnum.AdvertisingAgency,
    label: '広告会社'
  },
  {
    id: WorkspaceOpeningApplicationFormBusinessTypeEnum.Broadcaster,
    label: '放送局'
  }
];

// FIX ローディングの確認のため
export const sleep = (sec: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, sec * 1000));
};
